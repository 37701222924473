$('document').ready(function () {

    // toggle menu 
    $('.menu-box').click(function(){
        $('.menu-box').toggleClass('menu-cross');
        $('.menu').toggleClass('menu-slide');
    });

    $('.menu a li, .contact a').click(function(){
        $('.menu-box').removeClass('menu-cross');
        $('.menu').removeClass('menu-slide');
    });

    // fade in content for each page
    $('.menu a li:not(:first)').addClass('inactive');
        $('.tabs').hide();
        $('.tabs:first').show();

        $('.menu a li').click(function(){
        var t = $(this).attr('id');
        if($(this).hasClass('inactive')){ 
            $('.menu a li').addClass('inactive');           
            $(this).removeClass('inactive');

            $('.tabs').hide();
            $('#'+ t + 'C').fadeIn('slow');
        }
    });

    // about us slideshow
    document.documentElement.className = 'js'; // adds .js class to <html> tag

    var slides = document.getElementById('slideshow').getElementsByTagName('div');
    function slideShow(i){
        slides[i].className = '';
        if(i == slides.length -1){ slides[0].className = 'show'; i = -1; }
        if(i > -1 ){ slides[i+1].className = 'show'; }
            setTimeout(function(){ slideShow(++i % slides.length) }, 3000);
        }
    setTimeout(function(){ slides[0].className = 'show'; }, 1); 
    setTimeout(function(){ slideShow(0) }, 3000);

    // smooth scroll
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1500);
            return false;
            }
        }
    });

    // switch between forms
    $('.form-sam, .form-andy').hide();
    $('.form-sam').show();


    $('.andy').click(function(){
        $('.form-sam').hide();
        $('.form-andy').show();
    });

    $('.sam').click(function(){
        $('.form-andy').hide();
        $('.form-sam').show();
    });


    // When contact link is clicked it goes back to homepage
    $('.contact a').click(function(){
        var screenTop = $(document).scrollTop();
        var tabColor = { color : "#525252"};
        var tabColorActive = { color : "#B6316C"};
        console.log('test');

        if ($('#tab1C').css('display') == 'block') {
            screenTop.animate({
                scrollTop: $('#contact').offset().top
            }, 1000);
        } else {
            $('#tab2C, #tab3C').hide();
            $('li#tab2, li#tab3').addClass('inactive');
            $('li#tab1').removeClass('inactive');
            $('#tab1C').show();

            $('html, body').animate({
                scrollTop : $('#contact').offset().top
            }, 1000);
        }
    });

    // When form is complete and submit button has been clicked
    if (window.location.href.indexOf('php') > 0) {
        $('html, body').animate({
            scrollTop : $('#contact').offset().top
        }, 1000);
        console.log("The URL contains ?book");
    }

    if ($('.form-msg:contains("Your message has been sent!")').length > 0) {
        $('.form-sam, .form-andy').hide();
        $('.form-msg').addClass('form-msg-space');
    }

    // Read more link hide and show therapist copy
    $("#sam-read-more").on("click", function () {
        var txt = $("#sam-copy").is(':visible') ? 'Read More...' : 'Show Less...';
        $("#sam-read-more").text(txt);
        $('#sam-copy').slideToggle(1000);
    });

    $("#wendy-read-more").on("click", function () {
        var txt = $("#wendy-copy").is(':visible') ? 'Read More...' : 'Show Less...';
        $("#wendy-read-more").text(txt);
        $('#wendy-copy').slideToggle(1000);
    });

    $("#andy-read-more").on("click", function () {
        var txt = $("#andy-copy").is(':visible') ? 'Read More...' : 'Show Less...';
        $("#andy-read-more").text(txt);
        $('#andy-copy').slideToggle(1000);
    });

    $("#kim-read-more").on("click", function () {
        var txt = $("#kim-copy").is(':visible') ? 'Read More...' : 'Show Less...';
        $("#kim-read-more").text(txt);
        $('#kim-copy').slideToggle(1000);
    });

});